<input
  type="text"
  nbInput
  #emojiInputField
  [(ngModel)]="value"
  [placeholder]="placeholder"
  [readonly]="inputReadonly"
  [disabled]="inputDisabled"
  [required]="inputRequired"
  (blur)="onBlur.next()"
  (focus)="onFocus.next()"
  [name]="name"
  [id]="cid"
/>
<button mat-icon-button class="suffix" (click)="$event.stopPropagation(); emojiTableDisplayed = !emojiTableDisplayed">
  <i class="icon">sentiment_satisfied_alt</i>
</button>
<div class="emoji-picker" [class.hidden]="!emojiTableDisplayed" (click)="$event.stopPropagation()">
  <emoji-mart
    title="Select an emoji"
    emoji="point_up"
    (emojiClick)="onEmojiSelected($event)"
    [sheetSize]="32"
    [showPreview]="false"
    [perLine]="8"
    [darkMode]="false"
    [totalFrequentLines]="2"
    [emojiSize]="24"
  ></emoji-mart>
</div>
