import { Component, OnDestroy, OnInit } from '@angular/core';
import { CrudSelectorComponent } from '../crud-selector/crud-selector.component';
import { ConfirmationService } from '../../services/confirmation.service';
import { ClientService } from '../../services/client.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-client-selector',
  templateUrl: './../crud-selector/crud-selector.component.html',
  styleUrls: ['./../crud-selector/crud-selector.component.scss'],
})
export class ClientSelectorComponent extends CrudSelectorComponent implements OnInit, OnDestroy {
  constructor(confirmation: ConfirmationService, clientService: ClientService) {
    super(confirmation, clientService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
