import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { v4 as uuid } from 'uuid';

@Directive()
export class AbstractNgModelComponent<T = any> implements ControlValueAccessor {
  @Input()
  cid: string = uuid();

  @Input()
  disabled: boolean;

  @Input()
  set value(value: T) {
    this._value = value;
    this.notifyValueChange();
  }

  componentInit = false;

  get value(): T {
    return this._value;
  }

  onChange: (value: T) => {};
  onTouched: () => {};

  protected _value: T;
  // protected cdRef: ChangeDetectorRef;

  constructor() {
    // this.cdRef = AppInjector.get(ChangeDetectorRef);
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: T): void {
    this._value = value;
    this.valueChanged();
    // setTimeout(() => this.cdRef.detectChanges(), 0);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.componentInit = true;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  valueChanged() {} // custom implementation
}

@Directive()
export class AbstractInputComponent extends AbstractNgModelComponent<string> {
  @Input()
  readonly: any = false;

  @Input()
  required: any = false;

  @Input()
  disabled: any = false;

  @Input()
  placeholder: string = '';

  @Input()
  name: string = '';

  @Output()
  onBlur = new EventEmitter<void>();

  @Output()
  onFocus = new EventEmitter<void>();

  get inputReadonly(): boolean {
    return this.readonly || typeof this.readonly !== 'boolean';
  }

  get inputDisabled(): boolean {
    return this.disabled || typeof this.disabled !== 'boolean';
  }

  get inputRequired(): boolean {
    return this.required || typeof this.required !== 'boolean';
  }
}
