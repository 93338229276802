import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CategoryService } from '../../services/category.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { CrudSelectorComponent } from '../crud-selector/crud-selector.component';

@UntilDestroy()
@Component({
  selector: 'app-category-selector',
  templateUrl: './../crud-selector/crud-selector.component.html',
  styleUrls: ['./../crud-selector/crud-selector.component.scss'],
})
export class CategorySelectorComponent extends CrudSelectorComponent implements OnInit, OnDestroy {
  constructor(confirmation: ConfirmationService, categoryService: CategoryService) {
    super(confirmation, categoryService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
