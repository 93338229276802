import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

dayjs.locale('en-gb');

if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [environment.apiBaseUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Will catch 10% of errors (number selected for performance reasons)
    tracesSampleRate: 0.1,
  });

  enableProdMode();

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log(`Bootstrap success`))
    .catch((err) => console.error(err));
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
