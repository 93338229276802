import { Pipe, PipeTransform } from '@angular/core';
import { FileUtils } from '../utils/file.utils';

@Pipe({
  name: 'isImage',
})
export class IsImagePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return FileUtils.isImage(value);
  }
}
