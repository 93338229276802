import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { take } from 'rxjs/operators';

interface ConfirmParams {
  /**
   * Called when "Yes" is clicked
   */
  onSuccess: () => void;
  /**
   * Called when "No" is clicked
   */
  onDismiss?: () => void;
  /**
   * Message to display to the user.
   */
  message?: string;
  /**
   * The title of the dialog.
   */
  title?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  /**
   * Displays a confirmation dialogue, and returns the result using callbacks.
   * The configuration object should be self explanatory.
   */
  confirm({ onSuccess, onDismiss, message, title }: ConfirmParams) {
    const config: MatDialogConfig = {
      autoFocus: false,
    };
    if (message || title) {
      config.data = { message, title };
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (resp) => {
        if (resp) {
          onSuccess();
        } else if (onDismiss) {
          onDismiss();
        }
      });
  }

  /**
   * Displays a confirmation dialogue, and returns the result using a Promise<boolean>.
   * The promise will never reject.
   * The configuration object should be self explanatory.
   */
  confirmPromise(config?: Pick<ConfirmParams, 'message' | 'title'>) {
    return new Promise<boolean>((resolve, reject) => {
      this.confirm({
        onSuccess: () => resolve(true),
        onDismiss: () => resolve(false),
        ...config,
      });
    });
  }
}
