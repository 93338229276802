import { Component, ElementRef, forwardRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Emoji } from '../../models/emoji';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'app-emoji-input',
  templateUrl: './emoji-input.component.html',
  styleUrls: ['./emoji-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmojiInputComponent),
      multi: true,
    },
  ],
})
export class EmojiInputComponent extends AbstractInputComponent implements OnInit {
  @ViewChild('emojiInputField')
  emojiInputField: ElementRef;

  emojiTableDisplayed = false;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  public onEmojiSelected({ emoji }: { emoji: Emoji }) {
    const initialMessage = this.value || '';
    const nativeInput = this.emojiInputField.nativeElement;
    const caretPosition = nativeInput.selectionStart;
    const newMessage = `${initialMessage.slice(0, caretPosition)}${emoji.native}${initialMessage.slice(caretPosition)}`;

    this.value = newMessage;

    // Set the caret to the initial position + 2 bytes (emoji length)
    nativeInput.selectionStart = nativeInput.selectionEnd = caretPosition + 2;

    // Hide the emoji table after selection
    // this.emojiTableDisplayed = false;
  }

  @HostListener('window:click')
  onClick() {
    this.emojiTableDisplayed = false;
  }
}
