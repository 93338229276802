<div class="form-group" (click)="onTouched(); !inputReadonly && picker.open()">
  <input
    type="text"
    [placeholder]="placeholder"
    readonly
    [(ngModel)]="value"
    [matDatepicker]="picker"
    [name]="name"
    [id]="cid"
    [required]="inputRequired"
    [disabled]="inputDisabled"
    (blur)="onBlur.next()"
    (focus)="onFocus.next()"
    [min]="min"
    [max]="max"
    [matDatepickerFilter]="matDatepickerFilter"
  />
  <i class="icon suffix clear-icon" *ngIf="value" (click)="$event.stopPropagation(); clear()">clear</i>
  <i class="icon suffix" (click)="picker.open()">today</i>
  <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
</div>
