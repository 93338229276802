<div class="header">
  <span *ngIf="type === 'select'">{{ 'crudSel.selectOptions' | translate }}</span>
  <span *ngIf="type === 'range'">{{ 'crudSel.defineRange' | translate }}</span>
  <button type="button" mat-icon-button (click)="closed.emit()">
    <i class="icon-close"></i>
  </button>
</div>
<div class="body">
  <div id="range-input" *ngIf="type === 'range'">
    <input placeholder="From" type="number" [(ngModel)]="minRange" [required]="applyDisabled()" />
    <span>to</span>
    <input placeholder="To" type="number" [(ngModel)]="maxRange" [required]="applyDisabled()" />
  </div>

  <div id="select-input" *ngIf="type === 'select'">
    <ng-select [(ngModel)]="answers" [multiple]="true" placeholder="Select options">
      <ng-option *ngFor="let option of options" [value]="option">{{ option | textHumanize }}</ng-option>
    </ng-select>
  </div>
</div>
<div class="footer">
  <button mat-flat-button color="primary" (click)="closed.emit(true)" [disabled]="applyDisabled()">
    {{ 'crudSel.apply' | translate }}
  </button>
</div>
