import { Pipe, PipeTransform } from '@angular/core';
import { FileUtils } from '../utils/file.utils';

@Pipe({
  name: 'isVideo',
})
export class IsVideoPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return FileUtils.isVideo(value);
  }
}
