<section class="left">
  <div class="logo-section">
    <img src="/assets/images/logo.png" alt="Logo" />
    <div class="brand">{{ brand.name }}</div>
  </div>

  <div class="form">
    <h3 class="prompt">{{ 'login.login' | translate }}</h3>
    <div class="form-group">
      <input
        [(ngModel)]="email"
        type="email"
        id="email"
        name="email"
        (keyup.enter)="login()"
        placeholder="{{ 'login.email' | translate }}"
        autocomplete="email"
      />
    </div>
    <div class="form-group">
      <input
        [(ngModel)]="password"
        [type]="passwordRevealed ? 'text' : 'password'"
        id="password"
        name="password"
        autocomplete="current-password"
        (keyup.enter)="login()"
        placeholder="{{ 'login.password' | translate }}"
      />
      <button mat-icon-button class="reveal" (click)="passwordRevealed = !passwordRevealed">
        <i class="icon-eye"></i>
      </button>
    </div>
    <button mat-flat-button color="primary" (click)="login()" [appLoading]="isLoading">
      {{ 'login.login' | translate }}
    </button>

    <app-error [error]="error"></app-error>
  </div>

  <div class="links">
    <a href="https://mydialogues.com">Go back to the website</a>
  </div>
</section>

<section class="right">
  <img src="/assets/images/login.svg" />
</section>
