export const translations = {
  cancel: 'Cancel',
  yes: 'Yes',
  attention: 'Attention',
  areYouSure: 'Are you sure you want to perform this action?',
  filters: 'Filters',
  menu: {
    dashboard: 'Dashboard',
    dialogues: 'Dialogues',
    profileQs: 'Profile Qs',
    payouts: 'Payouts',
    respondents: 'Respondents',
    logout: 'Log out',
  },
  login: {
    email: 'Email',
    password: 'Password',
    emailPlaceholder: 'Enter your email',
    passwordPlaceholder: 'Enter your password',
    login: 'Log In',
  },
  upload: {
    dropHere: 'Drop your file here, or',
    clickToBrowse: 'click to browse',
    unsupportedType: 'Unsupported file type',
  },
  users: {
    empty: 'There are no users yet. Created profiles will appear here',
    emptyFiltered: 'No users found with your filters.',
    notFilledIn: '(not filled in)',
    id: 'User ID',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    balance: 'Balance',
    status: 'Status',
    actions: 'Actions',
    delete: 'Delete',
    statuses: {
      onboarding: 'Onboarding',
      active: 'Active',
      deleted: 'Deleted',
      inactive: 'Inactive',
    },
  },
  profileQs: {
    empty: "You did not set up any profile questions yet. Let's create one.",
    noMatch: "We could not find a match for what you've searched for.",
    title: 'Title',
    answers: 'Answers',
    options: 'options',
    max: 'max.',
    category: 'Category',
    type: 'Type',
    active: 'Active',
    addContent: 'Add Content',
    addQuestion: 'Add Question',
    createQuestion: 'Create Question',
    createContent: 'Create Content',
    viewStats: 'View Stats',
    searchQuestion: 'Search question',
    apiTypes: {
      multipleChoice: 'Multiple Choice',
      singleChoice: 'Single Choice',
      numeric: 'Numeric',
      intermediary: 'Content',
    },
  },
  dialogues: {
    title: 'Title',
    projectName: 'Project Name',
    createdAt: 'Created on',
    client: 'Client',
    clientEmpty: '(none)',
    questionCount: '# Q',
    answerScore: 'A.S.',
    status: 'Status',
    search: 'Search by title, client',
    addNew: 'Add new',
    statuses: {
      all: 'All dialogues',
      running: 'Running',
      finished: 'Finished',
      paused: 'Paused',
      draft: 'Draft',
    },
    menu: {
      edit: 'Edit Dialogue',
      duplicate: 'Duplicate as draft',
      pause: 'Pause',
      unpause: 'Unpause',
      delete: 'Delete',
    },
    apiTypes: {
      multipleChoice: 'Multiple Choice',
      singleChoice: 'Single Choice',
      numeric: 'Numeric',
      intermediary: 'Content',
      openEnded: 'Open Ended',
      slider: 'Slider',
      starRating: 'Star Rating',
      imageChoice: 'Image Choice',
      reorderItems: 'Reorder Items',
    },
    empty: "You don't have any dialogues yet. Let's create one.",
    noMatch: 'We could not find a match for your filters.',
    createDialogue: 'Create dialogue',
    settings: {
      titlePlaceholder: 'Enter Dialogue Title',
    },
  },
  qEditor: {
    createNewQuestion: 'Create a new question',
    editTheQuestion: 'Edit the question',
    createNewContent: 'Create new content',
    editTheContent: 'Edit the content',
    contentTextSubtext:
      'You can add intermediate text lines to enhance the user experience, and make it more friendly and conversational.',
    contentImageSubtext: 'You can add intermediate images, related to a question.',
    contentVideoSubtext: 'You can link a Vimeo video for your respondents to watch before moving to the next question.',
    typeRequired: 'Type is required',
    questionTitlePlaceholder: 'Write your question here...',
    contentTitlePlaceholder: 'Write your text here...',
    contentVideoPlaceholder: 'Paste your Vimeo embed link here...',
    descriptionPlaceholder: 'Add description... (optional)',
    discard: 'Discard',
    saveQuestion: 'Save Question',
    saveContent: 'Save Content',
    option: 'Option',
    addNewOption: 'Add new option',
    typeAnAnswer: 'Type an answer',
    answer: 'Answer',
    userWillTypeNumber: 'User will type a number in the text field',
    maximumNumberAllowed: 'Maximum number allowed',
    numberOfCharsAllowed: 'Number of characters allowed',
    userWillBeAbleToMoveSlider: 'User will be able to move the slider freely',
    sliderRange: 'Slider Range',
    to: 'to',
    rightLabel: 'Right Label',
    leftLabel: 'Left Label',
    dismiss: 'Are you sure you want to dismiss? All unsaved progress will be lost!',
    randomizeAnswers: 'Randomize answers',
  },
  payouts: {
    userId: 'User Id',
    date: 'Date',
    week: 'Week',
    points: 'Points',
    money: 'Money',
    status: 'Status',
    actions: 'Actions',
    markAsPaid: 'Mark as Paid',
    markAsUnpaid: 'Mark as Unpaid',
    empty: 'There are no payouts requested yet.',
    statuses: {
      paid: 'Paid',
      unpaid: 'Unpaid',
    },
  },
  crudSel: {
    // category selector
    selectOptions: 'Select one or multiple options',
    defineRange: 'Define a range',
    select: 'Select an option',
    selectOrCreate: 'Select an option or create one',
    typeHere: 'Type here',
    create: 'Create',
    edit: 'Edit',
    apply: 'Apply',
    saveChanges: 'Save changes',
  },
  toast: {
    changeSuccess: 'Your change has been successfully saved.',
    contentCreated: 'The content was successfully created',
    questionCreated: 'The question was successfully created',
  },
};
