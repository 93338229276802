import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from '../models/category';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends CrudService<Category> {
  constructor(protected http: HttpClient) {
    super(http, 'categories');
  }
}
