<ng-select
  [items]="options"
  [placeholder]="placeholder"
  [(ngModel)]="values"
  (ngModelChange)="valueChanged($event)"
  [multiple]="multiple"
  [bindLabel]="null"
  [bindValue]="null"
  [closeOnSelect]="!multiple"
  #select
>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-select-multiple-label">
      <span class="number">{{ items.length }}</span>
      <span class="clear" (click)="select.handleClearClick()" aria-hidden="true">×</span>
    </div>
  </ng-template>

  <ng-template *ngIf="imageField" ng-label-tmp let-item="item">
    <div class="image-option">
      <app-avatar [src]="item.image" width="32" type="square"></app-avatar>
      {{ item.label }}
    </div>
  </ng-template>

  <ng-template
    *ngIf="imageField"
    ng-option-tmp
    let-item="item"
    let-index="index"
    let-search="searchTerm"
    class="custom"
  >
    <div class="image-option">
      <app-avatar [src]="item.image" type="square"></app-avatar>
      <div>{{ item.label }}</div>
    </div>
  </ng-template>
</ng-select>
