import { translations } from './language.current';

export class Translate {
  public static key(path: string, params: Record<string, string> = {}) {
    // search in the tree for the path
    const parts = path.split('.');
    let current = translations as any;
    for (const part of parts) {
      current = current && current[part];
    }
    if (current === undefined) {
      console.warn('Missing translate string', path);
      return `[${path}]`;
    }
    let template = current as string;
    // perform substitution
    for (const key in params) {
      template = template.replace(new RegExp(`{${key}}`, 'g'), params[key]);
    }
    // return substituted string
    return template;
  }
}
