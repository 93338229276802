import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { DateFilterFn, MatCalendarCellClassFunction } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  @Input() startField = 'startDate';
  @Input() endField = 'endDate';
  @Input() placeholder = 'Select a date range';

  @Input() min: Date;
  @Input() max: Date;
  @Input() dateClass: MatCalendarCellClassFunction<Date>;

  start: Date;
  end: Date;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParamMap.pipe(take(1)).subscribe((params) => {
      const startStr = params.get(this.startField);
      const endStr = params.get(this.endField);

      const dayjsStart = dayjs(startStr);
      const dayjsEnd = dayjs(endStr);

      if (dayjsStart.isValid()) this.start = dayjsStart.toDate();
      if (dayjsEnd.isValid()) this.end = dayjsEnd.toDate();
    });
  }

  dateChanged() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [this.startField]: this.start?.toISOString() ?? null,
        [this.endField]: this.end?.toISOString() ?? null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  clear() {
    this.start = null;
    this.end = null;
    this.dateChanged();
  }
}
