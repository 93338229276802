import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import getBlobDuration from 'get-blob-duration';
import { UploadService } from '../../services/upload.service';
import { FileUtils } from '../../utils/file.utils';
import { Translate } from '../../../../localization/translate';

export interface FileDetails {
  file?: File;
  filename?: string;
  size?: number;
  dataUrl?: string | ArrayBuffer | SafeResourceUrl;
  extension?: string;
  width?: number;
  height?: number;
  duration?: number;
  type?: string;
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnChanges {
  // Message to display below the drag-and-drop area
  @Input() message;

  // Accepted file mime types
  @Input() accept = '*';

  // Shape of the drag-and-drop area
  @Input() shape: 'circle' | 'square' = 'square';

  // Programmatically added picture url
  @Input() dataUrl: string;

  // Programmatically added file type
  @Input() dataType: 'image' | 'audio' | 'video' | null;

  // Programmatically added file key
  @Input() uploadKey: string;

  // If resizing the image, specify the maximum size in px (eg. max width / height is 1500px)
  @Input() imageMaxDimension = 1500;

  // Compression ratio: 0 min - 1 max
  @Input() imageResizeCompressionQuality = 0.85;

  // Which mimetypes to resize if resize = true
  @Input() resizedMimetypes = ['image/jpg', 'image/jpeg', 'image/png'];

  // Should resize the file if it's an image or not
  @Input() resize = true;

  // Should show file details or not
  @Input() showInfo = true;

  // Condense paddings
  @Input() condensed = false;

  // emits when file upload is finished and when file is canceled
  // emits an object with two properties
  // file - File object
  // picture - uploaded picture buffer
  // when file is canceled, both properties of emitted object are null
  @Output() change: EventEmitter<FileDetails> = new EventEmitter();

  fileDetails: FileDetails; // Shown on the upload component
  finalFileDetails: FileDetails; // Emitted to the parent component on change (includes resize metadata)

  isLoading = false;

  constructor(private uploadService: UploadService, private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.uploadKey && changes.uploadKey.previousValue !== changes.uploadKey.currentValue && this.uploadKey) {
      // Get upload from API to display metadata
      if (this.showInfo) {
        this.loadFileMetadataFromApi();
      }
    }
  }

  async loadFileMetadataFromApi() {
    try {
      const upload = await this.uploadService.getUploadByKey(this.uploadKey);
      if (upload.metadata) {
        this.fileDetails = {
          ...upload.metadata,
          extension: upload.mimetype.split('/').pop(),
        };
      }
    } catch (error) {
      console.error(error);
    }
  }

  onFileSelected(event: File) {
    if (!event) return;

    // this will keep us safe in the case of 'drag and drop'
    if (this.accept !== '*') {
      const acceptedTypes = this.accept.split(',');
      if (!acceptedTypes.includes(event.type)) {
        console.error(Translate.key('upload.unsupportedType'));
        return;
      }
    }

    this.fileDetails = {
      file: event,
      filename: event.name,
      size: event.size,
      extension: /(?:\.([^.]+))?$/.exec(event.name)[0],
      type: event.type,
    };

    this.finalFileDetails = { ...this.fileDetails };

    if (FileUtils.isImage(event.type)) {
      this.isLoading = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.onImageLoaded(reader.result);
      };
      reader.onabort = (e) => {
        this.isLoading = false;
      };
      reader.onerror = (e) => {
        this.isLoading = false;
      };
      reader.readAsDataURL(event);
    }

    if (FileUtils.isAudio(event.type)) {
      const reader = new FileReader();
      this.isLoading = true;
      reader.onload = (e) => {
        this.onAudioLoaded(reader.result);
      };
      reader.onabort = (e) => {
        this.isLoading = false;
      };
      reader.onerror = (e) => {
        this.isLoading = false;
      };
      reader.readAsDataURL(event);
    }

    if (FileUtils.isVideo(event.type)) {
      const reader = new FileReader();
      this.isLoading = true;
      reader.onload = (e) => {
        this.onVideoLoaded(reader.result);
      };
      reader.onabort = (e) => {
        this.isLoading = false;
      };
      reader.onerror = (e) => {
        this.isLoading = false;
      };
      reader.readAsDataURL(event);
    }
  }

  onCancel(event) {
    event.stopPropagation();
    event.preventDefault();
    this.fileDetails = null;
    this.finalFileDetails = null;
    this.dataUrl = null;
    this.uploadKey = null;
    this.dataType = null;
    this.emitChanges();
  }

  onImageLoaded(result: any) {
    const file = this.fileDetails?.file;
    if (!file || !file.type.startsWith('image/')) {
      console.error(Translate.key('upload.unsupportedType'));
      this.isLoading = false;
      return;
    }

    if (!this.resize || !this.resizedMimetypes.includes(file.type)) {
      this.fileDetails.dataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result);
    }

    if (file.type === 'image/svg+xml') {
      this.isLoading = false;
      this.emitChanges();
      return;
    }

    const img = new Image();
    img.addEventListener('load', async () => {
      this.fileDetails.width = img.width;
      this.fileDetails.height = img.height;
      this.finalFileDetails = { ...this.fileDetails };
      if (this.resize && this.resizedMimetypes.includes(file.type)) {
        const { imageUrl, newWidth, newHeight } = this.resizeImage(img);
        this.fileDetails.dataUrl = imageUrl;
        const resizedFile = await this.urltoFile(imageUrl, file.name, file.type);
        this.fileDetails.file = resizedFile;
        this.finalFileDetails.file = resizedFile;
        this.finalFileDetails.width = newWidth;
        this.finalFileDetails.height = newHeight;
        this.finalFileDetails.size = resizedFile.size;
      }
      this.isLoading = false;
      this.emitChanges();
    });
    img.src = URL.createObjectURL(file);
  }

  onAudioLoaded(result: any) {
    const file = this.fileDetails?.file;
    if (!file || !file.type.startsWith('audio/')) {
      console.error(Translate.key('upload.unsupportedType'));
      this.isLoading = false;
      return;
    }

    // Some types of audio files have problems obtaining duration from the HTML Audio DOM.
    // That's an convenience method to patch Chromium bug when getting duration of HTML5 video or audio Blob.
    // this.fileDetails.duration = await getBlobDuration(result)

    const audio = new Audio();
    audio.addEventListener('loadeddata', async () => {
      this.fileDetails.dataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(audio.src);
      this.fileDetails.duration = audio.duration;
      this.finalFileDetails = { ...this.fileDetails };
      this.isLoading = false;
      this.emitChanges();
    });
    audio.src = URL.createObjectURL(file);
  }

  onVideoLoaded(result: any) {
    const file = this.fileDetails?.file;
    if (!file || !file.type.startsWith('video/')) {
      console.error(Translate.key('upload.unsupportedType'));
      this.isLoading = false;
      return;
    }

    const video = document.createElement('video');
    video.addEventListener('loadeddata', async () => {
      this.fileDetails.width = video.videoWidth;
      this.fileDetails.height = video.videoHeight;
      this.fileDetails.duration = video.duration;
      this.fileDetails.dataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(video.src);
      this.finalFileDetails = { ...this.fileDetails };
      this.isLoading = false;
      this.emitChanges();
    });
    video.src = URL.createObjectURL(file);
  }

  urltoFile(url, filename, mimeType): Promise<File> {
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  resizeImage(image: HTMLImageElement, size: number = this.imageMaxDimension) {
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;
    let newWidth;
    let newHeight;

    if (imageWidth < size && imageHeight < size) {
      newWidth = imageWidth;
      newHeight = imageHeight;
    } else if (imageWidth > imageHeight) {
      newWidth = size;
      newHeight = Math.floor((newWidth / imageWidth) * imageHeight);
    } else {
      newHeight = size;
      newWidth = Math.floor((newHeight / imageHeight) * imageWidth);
    }

    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, newWidth, newHeight);

    const isPng = this.fileDetails.file.type === 'image/png';
    if (!isPng) {
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imgData.data;
      for (var i = 0; i < data.length; i += 4) {
        if (data[i + 3] < 255) {
          data[i] = 255 - data[i];
          data[i + 1] = 255 - data[i + 1];
          data[i + 2] = 255 - data[i + 2];
          data[i + 3] = 255 - data[i + 3];
        }
      }
      ctx.putImageData(imgData, 0, 0);
    }

    const imageUrl = canvas.toDataURL(isPng ? 'image/png' : 'image/jpeg', this.imageResizeCompressionQuality);
    return { imageUrl, newWidth, newHeight };
  }

  emitChanges() {
    this.change.emit({
      file: this.finalFileDetails?.file ?? null,
      filename: this.finalFileDetails?.filename,
      height: this.finalFileDetails?.height,
      width: this.finalFileDetails?.width,
      size: this.finalFileDetails?.size,
      dataUrl: this.fileDetails?.dataUrl,
      type: this.finalFileDetails?.type,
      duration: this.finalFileDetails?.duration,
    });
  }
}
