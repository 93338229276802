import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-row',
  templateUrl: './empty-row.component.html',
  styleUrls: ['./empty-row.component.scss'],
})
export class EmptyRowComponent implements OnInit {
  @Input() public translateKey;

  constructor() {}

  ngOnInit(): void {}
}
