import { Pipe, PipeTransform } from '@angular/core';
import { FileUtils } from '../utils/file.utils';

@Pipe({
  name: 'isAudio',
})
export class IsAudioPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return FileUtils.isAudio(value);
  }
}
