import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const duration = parseInt(value);
    const hours = Math.trunc(duration / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2 });
    const minutes = Math.trunc((duration % 3600) / 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
    const seconds = Math.trunc(duration % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
    if (duration / 3600 >= 1) {
      return `${hours}:${minutes}:${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }
}
