export class ErrorUtils {
  public static toString(error: any): string {
    if (error == null) return '';
    if (typeof error === 'string') {
      return error;
    }
    if (typeof error === 'object') {
      return (error.error || {}).message || error.message;
    }
    return '';
  }
}
