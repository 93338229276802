import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-match',
  templateUrl: './no-match.component.html',
  styleUrls: ['./no-match.component.scss'],
})
export class NoMatchComponent implements OnInit {
  @Input() translateKey: string;
  constructor() {}

  ngOnInit(): void {}
}
