import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ErrorUtils } from '../utils/error.utils';

const SNACKBAR_OPTIONS: MatSnackBarConfig = {
  horizontalPosition: 'start',
  verticalPosition: 'bottom',
  duration: 4000,
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snackbar: MatSnackBar) {}

  show(message: string) {
    this.snackbar.open(message, '✕', { ...SNACKBAR_OPTIONS, panelClass: ['snackbar'] });
  }

  showError(error: any) {
    const message = ErrorUtils.toString(error);
    this.snackbar.open(message, '✕', { ...SNACKBAR_OPTIONS, panelClass: ['snackbar', 'error-snackbar'] });
  }

  showSuccess(message: string) {
    this.snackbar.open(message, '✕', { ...SNACKBAR_OPTIONS, panelClass: ['snackbar', 'success-snackbar'] });
  }
}
