import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-query-filters',
  templateUrl: './query-filters.component.html',
  styleUrls: ['./query-filters.component.scss'],
})
export class QueryFiltersComponent implements OnInit {
  @Input() queryParams = '';
  routeParams: string[] = [];

  queryTags: { key: string; value: string; type: string }[] = [];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.routeParams = (this.queryParams ?? '').split(',');

    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((queryParams) => {
      this.queryTags = [];
      queryParams.keys.forEach((key) => {
        if (!this.routeParams.includes(key)) return;

        const value = queryParams.get(key);
        const asDate = Date.parse(value);
        let type = 'string';
        if (!isNaN(asDate) && new Date(asDate).toISOString() === value) {
          type = 'date';
        }
        this.queryTags.push({ key, value, type });
      });
    });
  }

  removeFilter(tag: { key: string }) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [tag.key]: null },
      queryParamsHandling: 'merge',
    });
  }
}
