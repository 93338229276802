export const environment = {
  name: 'Dev',
  production: false,
  apiBaseUrl: 'https://dev.api.crowdbeatz.nl/admin',
  brand: {
    name: 'Crowdbeatz',
  },
  sentryDSN: '',
  passwordDialogPassword: '',
  hideWalletRelatedFeatures: true,
  roundDashboardValues: false,
};
