<section class="toolbar">
  <div>
    <img src="/assets/images/dashboard.svg" alt="Logo" class="logo" />
  </div>
  <div>
    <button mat-button class="menu-button" (click)="menuToggle.emit()">
      <i class="icon"> menu </i>
    </button>
  </div>
</section>
