import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

export enum InputTypes {
  range = 'range',
  select = 'select',
}

@UntilDestroy()
@Component({
  selector: 'app-multi-input',
  templateUrl: './multi-input.component.html',
  styleUrls: ['./multi-input.component.scss'],
})
export class MultiInputComponent implements OnInit, OnDestroy {
  @Input() type: InputTypes | string = InputTypes.select;
  /* SELECT INPUT TYPE */
  @Input() answers?: string[];
  @Input() options: string[];
  /* RANGE INPUT TYPE */
  @Input() minRange: number;
  @Input() maxRange: number;
  @Output() closed = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  applyDisabled(): boolean {
    switch (this.type) {
      case InputTypes.range:
        return !!((this.minRange && !this.maxRange) || (!this.minRange && this.maxRange));
      case InputTypes.select:
        return false;
    }
    return true;
  }
}
