import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfirmationService } from '../../services/confirmation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CrudService } from '../../services/crud.service';
import { BaseModel } from '../../models/base-model';

interface Element extends BaseModel {
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'app-crud-selector',
  templateUrl: './crud-selector.component.html',
  styleUrls: ['./crud-selector.component.scss'],
})
export class CrudSelectorComponent implements OnInit, OnDestroy {
  @Input() element?: Element;
  @Output() elementChange = new EventEmitter<Element>();
  @Output() closed = new EventEmitter();

  search = new FormControl();

  elements: Element[] = [];

  shownElements = [...this.elements];

  editedElement: Element = null;
  originalElement: Element = null;

  createAvailable = false;

  isLoading = true;

  constructor(protected confirmation: ConfirmationService, protected elementService: CrudService<any>) {}

  ngOnInit(): void {
    this.search.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => this.onTextInput(val));

    this.fetchData();
  }

  ngOnDestroy(): void {
    if (this.editedElement) this.cancelEditing();
  }

  async fetchData() {
    this.createAvailable = false;
    this.isLoading = true;

    const response = await this.elementService.getAll({});
    this.elements = response.data;
    this.shownElements = [...this.elements];

    this.selectElement(this.element);
    this.isLoading = false;
  }

  selectElement(element: Element) {
    if (this.editedElement) this.cancelEditing();
    this.element = this.elements.find((elem) => elem._id === element?._id) ?? null;
    this.elementChange.emit(element);
  }

  startEditing(elem: Element) {
    this.originalElement = { ...elem };
    this.editedElement = elem;
  }

  cancelEditing() {
    const idx = this.elements.findIndex((c) => c._id === this.originalElement._id);
    this.elements[idx] = this.originalElement;
    this.element = this.originalElement;
    this.elementChange.emit(this.element);
    this.editedElement = null;
    this.originalElement = null;
    this.shownElements = [...this.elements];
  }

  async saveChanges() {
    this.isLoading = true;
    await this.elementService.partiallyUpdateOne(this.editedElement._id, {
      name: this.editedElement.name,
    });
    this.editedElement = null;
    await this.fetchData();
    this.isLoading = false;
  }

  startDelete(elem: Element) {
    this.confirmation.confirm({
      onSuccess: async () => {
        this.isLoading = true;
        await this.elementService.deleteOne(elem._id);
        await this.fetchData();
        this.selectElement(null);
        this.isLoading = false;
      },
    });
  }

  onTextInput(val: string) {
    if (!val) {
      this.shownElements = this.elements;
      this.createAvailable = false;
    } else {
      this.shownElements = this.elements.filter((elem) => elem.name.toLowerCase().includes(val.toLowerCase()));
      this.element = null;
      this.elementChange.emit(null);
      this.createAvailable = this.elements.every((elem) => elem.name !== val);
    }
  }

  async create() {
    this.isLoading = true;
    const newElem = await this.elementService.addOne({
      name: this.search.value,
    });
    await this.fetchData();
    this.search.setValue(undefined);
    this.selectElement(this.elements.find((elem) => elem._id === newElem._id));
    this.isLoading = false;
  }
}
