<div class="header">
  <span>{{ 'crudSel.selectOrCreate' | translate }}</span>
  <button type="button" mat-icon-button (click)="closed.emit()">
    <i class="icon-close"></i>
  </button>
</div>

<input
  type="text"
  placeholder="{{ 'crudSel.typeHere' | translate }}"
  [formControl]="search"
  *ngIf="!editedElement"
  (keyup.enter)="createAvailable && create()"
/>
<input
  type="text"
  placeholder="{{ 'crudSel.typeHere' | translate }}"
  [(ngModel)]="editedElement.name"
  *ngIf="editedElement"
/>

<div class="body-container" [appLoading]="isLoading">
  <div class="body">
    <div class="row" *ngIf="createAvailable">
      <div>
        <button type="button" class="element" [class.selected]="!element">{{ search.value }}</button>
      </div>
      <div class="options">
        <button type="button" mat-flat-button (click)="create()">{{ 'crudSel.create' | translate }}</button>
      </div>
    </div>
    <div class="row" *ngFor="let elem of shownElements">
      <div>
        <button type="button" class="element" [class.selected]="element === elem" (click)="selectElement(elem)">
          {{ elem.name }}
        </button>
      </div>
      <div class="options" *ngIf="element === elem">
        <button type="button" mat-flat-button *ngIf="!editedElement" (click)="startEditing(elem)">
          {{ 'crudSel.edit' | translate }}
        </button>
        <button type="button" mat-flat-button *ngIf="editedElement" (click)="saveChanges()">
          {{ 'crudSel.saveChanges' | translate }}
        </button>
        <button type="button" mat-icon-button *ngIf="!editedElement" (click)="startDelete(elem)">
          <i class="icon-trash"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <button
    mat-flat-button
    color="primary"
    (click)="closed.emit()"
    [disabled]="!editedElement && !isLoading ? null : true"
  >
    {{ 'crudSel.apply' | translate }}
  </button>
</div>
