import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss'],
})
export class PasswordDialogComponent implements OnInit {
  public password: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { password: string },
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    private toastService: ToastService
  ) {}

  ngOnInit() {}

  verifyPassword() {
    if (this.data.password === this.password) this.dialogRef.close(true);
    else this.toastService.showError('The password is incorrect.');
  }
}
