import { ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ComponentType, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseModel } from '../models/base-model';

@UntilDestroy()
@Directive({
  selector: '[appCrudOverlay]',
})
export class CrudOverlayDirective {
  @Input() crudValue: BaseModel;
  @Input() crudField: string = 'name'; // in case we don't have the name field but instead something like title
  @Output() crudValueChanged: EventEmitter<BaseModel> = new EventEmitter();
  @Input() component: ComponentType<any>;

  instance: any;

  constructor(private overlay: Overlay, private elementRef: ElementRef) {}

  ngOnInit() {}

  @HostListener('click')
  show() {
    // this checks if there is not another instance running
    if (!this.instance?.overlayRef._host) {
      const overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.elementRef)
          .withPositions([
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
            },
          ]),
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        hasBackdrop: true,
      });
      overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
      const crudRef = overlayRef.attach(new ComponentPortal(this.component));
      if (this.crudValue) {
        crudRef.instance.element = { ...this.crudValue } as any;
      }
      this.instance = new ElementSelectorInstance(overlayRef, crudRef);
      this.instance
        .elementChanged$()
        .pipe(untilDestroyed(this))
        .subscribe((option) => {
          if (option) {
            // we do it like this to keep the reference passed from the parent
            this.crudValue[this.crudField] = option[this.crudField];
            this.crudValue._id = option._id;
            this.crudValueChanged.emit(this.crudValue);
          }
        });
    }
  }
}

class ElementSelectorInstance {
  constructor(protected overlayRef: OverlayRef, protected componentRef: ComponentRef<any>) {
    componentRef.instance.closed.subscribe(() => {
      this.componentRef.onDestroy(() => {});
      this.dispose();
    });
  }

  dispose() {
    this.overlayRef.dispose();
  }

  elementChanged$() {
    return this.componentRef.instance.elementChange.asObservable();
  }
}
