import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AdminType } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Translate } from '../../../localization/translate';
import { ToastService } from '../../shared/services/toast.service';
import { environment } from 'src/environments/environment';

export interface MenuItem {
  title: string;
  link?: string;
  icon?: string;
  matIcon?: string;
  submenu?: { title: string; link: string }[];
  expanded?: boolean;
  roles: AdminType[];
  if?: boolean;
}

const allMenuItems: MenuItem[] = [
  {
    title: Translate.key('menu.dashboard'),
    link: '/admin/dashboard',
    icon: 'dashboard',
    roles: [AdminType.content, AdminType.superadmin],
  },
  {
    title: Translate.key('menu.dialogues'),
    link: '/admin/dialogues',
    roles: [AdminType.content, AdminType.superadmin],
    icon: 'chat-bubble',
  },
  {
    title: Translate.key('menu.profileQs'),
    link: '/admin/profile-questions',
    roles: [AdminType.content, AdminType.superadmin],
    icon: 'user-stroke',
  },
  {
    title: Translate.key('menu.payouts'),
    link: '/admin/payouts',
    roles: [AdminType.content, AdminType.superadmin],
    icon: 'card',
    if: !environment.hideWalletRelatedFeatures,
  },
  {
    title: Translate.key('menu.respondents'),
    link: '/admin/users',
    roles: [AdminType.content, AdminType.superadmin],
    icon: 'group',
  },
  // {
  //   title: Translate.key('menu.settings'),
  //   link: '/admin/settings',
  //   roles: [AdminType.content, AdminType.superadmin],
  //   // icon: 'settings-cog', // Valentina's is broken :(
  //   matIcon: 'settings',
  // },
];

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @ViewChildren(MatMenu) menus: QueryList<MatMenu>;

  @Input() isMobile = false;
  @Input() isCondensed = false;

  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  activeLink: string;
  isLoggingOut = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.urlChanged(this.router.url);
  }

  get menuItems() {
    // if item.if !== false, then
    // - it is true, then we need to show
    // - it is undefined, then we need to show
    // otherwise
    // - it is false, we don't show
    return this.auth.user
      ? allMenuItems.filter((item) => item.roles.includes(this.auth.user.type) && item.if !== false)
      : [];
  }

  get condensed() {
    return this.isMobile ? false : this.isCondensed;
  }

  urlChanged(url) {
    this.activeLink = url.split('?')[0];
    this.setExpanded();
    this.cdr.detectChanges();
  }

  setExpanded() {
    this.menuItems
      .filter((item) => item.submenu != null)
      .forEach((item) => {
        item.expanded = item.submenu.map((i) => i.link).includes(this.activeLink);
      });
  }

  async onLogout() {
    try {
      this.isLoggingOut = true;
      await this.auth.logout();
      this.logout.emit();
    } catch (error) {
      this.toast.showError(error);
    } finally {
      this.isLoggingOut = false;
    }
  }

  itemClick(item: MenuItem) {
    if (item.submenu && !this.condensed) {
      item.expanded = !item.expanded;
    }
  }
}
