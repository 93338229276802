import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

@NgModule({
  declarations: [LoginComponent, SidenavComponent, ToolbarComponent],
  imports: [CommonModule, SharedModule],
  exports: [LoginComponent, SidenavComponent, ToolbarComponent],
})
export class CoreModule {}
