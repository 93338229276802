import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateFilterFn, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent extends AbstractInputComponent implements OnInit {
  @Input() min: Date;
  @Input() max: Date;
  @Input() matDatepickerFilter: DateFilterFn<Date>;
  @Input() dateClass: MatCalendarCellClassFunction<Date>;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  clear() {
    this.value = null;
  }
}
