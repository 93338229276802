<div class="form-group" (click)="picker.open()">
  <input
    type="text"
    [placeholder]="placeholder"
    readonly
    [(ngModel)]="date"
    [matDatepicker]="picker"
    (ngModelChange)="dateChanged()"
    [min]="min"
    [max]="max"
    [matDatepickerFilter]="matDatepickerFilter"
  />
  <i class="icon suffix clear-icon" *ngIf="date" (click)="$event.stopPropagation(); clear()">clear</i>
  <i class="icon suffix" (click)="picker.open()">today</i>
  <mat-datepicker #picker [dateClass]="dateClass"></mat-datepicker>
</div>
