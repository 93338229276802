import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Translate } from '../../../localization/translate';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: unknown, ...args: any[]): unknown {
    if (typeof value !== 'string') throw new Error("Cannot translate something that's not a string");
    return Translate.key(value, args[0] ?? {});
  }
}
