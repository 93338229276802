import { Component, Input, OnInit } from '@angular/core';
import { DateFilterFn, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import * as dayjs from 'dayjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Input() field = 'date';
  @Input() placeholder = 'Select a date';

  @Input() min: Date;
  @Input() max: Date;
  @Input() matDatepickerFilter: DateFilterFn<Date>;
  @Input() dateClass: MatCalendarCellClassFunction<Date>;

  date: Date;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParamMap.pipe(take(1)).subscribe((params) => {
      const dateStr = params.get(this.field);
      const dayjsDate = dayjs(dateStr);
      if (dayjsDate.isValid()) this.date = dayjsDate.toDate();
    });
  }

  dateChanged() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [this.field]: this.date?.toISOString() ?? null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  clear() {
    this.date = null;
    this.dateChanged();
  }
}
