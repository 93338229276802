import { BaseModel } from './base-model';

export enum AdminType {
  superadmin = 'superadmin',
  content = 'content',
}
export const AdminTypeAll = Object.values(AdminType);

export enum UserStatus {
  onboarding = 'onboarding',
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum Gender {
  male = 'male',
  female = 'female',
  other = 'other',
  ratherNotSay = 'ratherNotSay',
}

export interface ProfileLocation {
  country: string;
  zipCode?: string;
}

export interface User extends BaseModel {
  phone: string;
  name?: string;
  status: UserStatus;
  email?: string;
  dateOfBirth?: Date;
  gender?: Gender;
  location?: ProfileLocation;
}

export interface Admin extends BaseModel {
  type: AdminType;
  // only for editing
  password?: string;
}
