<header [class.has-filters]="filters.children.length !== 0">
  <app-search-bar *ngIf="includeSearch"></app-search-bar>
  <section *ngIf="filters.children.length !== 0 || tableColumns">
    <button
      *ngIf="filters.children.length !== 0"
      mat-flat-button
      class="filters-expand"
      [class.expanded]="filtersExpanded"
      (click)="expansionPanel.toggle()"
    >
      {{ 'filters' | translate }} <i class="icon">expand_more</i>
    </button>
    <ng-container *ngIf="tableColumns">
      <button mat-icon-button [matMenuTriggerFor]="columnsMenu" matTooltip="Table columns">
        <i class="icon">more_horiz</i>
      </button>
      <mat-menu #columnsMenu="matMenu">
        <div class="table-columns">
          <mat-checkbox
            *ngFor="let column of tableColumns"
            color="primary"
            (click)="$event.stopPropagation()"
            [(ngModel)]="column.checked"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="onColumnsChanged()"
            >{{ column.name | textHumanize }}</mat-checkbox
          >
        </div>
      </mat-menu>
    </ng-container>
  </section>
</header>

<app-query-filters *ngIf="queryParams" [queryParams]="queryParams"></app-query-filters>

<mat-expansion-panel class="no-styling" #expansionPanel [(expanded)]="filtersExpanded">
  <section class="filters" #filters>
    <ng-content></ng-content>
  </section>
</mat-expansion-panel>
