<ng-select
  [items]="items$ | async"
  [placeholder]="placeholder"
  [typeahead]="input$"
  [loading]="isLoading"
  [(ngModel)]="value"
  [name]="name"
  [id]="cid"
  [required]="inputRequired"
  [readonly]="inputReadonly"
  [disabled]="inputDisabled"
  (blur)="onBlur.next()"
  (focus)="onFocus.next()"
  (open)="onTouched()"
  (change)="onTouched()"
  (clear)="onTouched()"
>
  <ng-template *ngIf="imageField" ng-label-tmp let-item="item">
    <div class="image-option">
      <app-avatar [src]="item.image" width="32" type="square"></app-avatar>
      {{ item.label }}
    </div>
  </ng-template>

  <ng-template
    *ngIf="imageField"
    ng-option-tmp
    let-item="item"
    let-index="index"
    let-search="searchTerm"
    class="custom"
  >
    <div class="image-option">
      <app-avatar [src]="item.image" type="square"></app-avatar>
      <div>{{ item.label }}</div>
    </div>
  </ng-template>
</ng-select>
