import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PagedResponse } from '../models/paged-response';

const MAX_SUGGESTIONS = 10;

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  constructor(private http: HttpClient) {}

  search(options: {
    url: string;
    search: string;
    valueField: string;
    labelField: string;
    additionalFields?: string;
    imageField?: string;
    queryParams?: { [key: string]: string };
    maxSuggestions?: number;
  }) {
    const {
      url,
      search,
      valueField,
      labelField,
      imageField,
      additionalFields,
      queryParams = {},
      maxSuggestions = MAX_SUGGESTIONS,
    } = options;

    const params = {
      ...(search && { search }),
      ...queryParams,
      start: '0',
      length: `${maxSuggestions}`,
      ...(labelField && {
        summary: [
          valueField,
          labelField,
          ...(imageField ? [imageField] : []),
          ...(additionalFields ? additionalFields?.split(',') : []),
        ].join(','),
      }),
    };

    return this.http.get<PagedResponse<any>>(url, { params }).pipe(map((resp) => resp.data));
  }

  findByIds(url: string, ids: string) {
    const params = { ids };
    return this.http.get<PagedResponse<any>>(url, { params }).pipe(map((resp) => resp.data));
  }
}
