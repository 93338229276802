export class FileUtils {
  static isImage(type?: string): boolean {
    return type?.startsWith('image') === true;
  }
  static isAudio(type?: string): boolean {
    return type?.startsWith('audio') === true;
  }
  static isVideo(type: string): boolean {
    return type?.startsWith('video') === true;
  }
}
